import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyBF82QyoxX_l5sJNP75eVuwj86XJkxXb38',
  authDomain: 'codex-bautista.firebaseapp.com',
  projectId: 'codex-bautista',
  storageBucket: 'codex-bautista.appspot.com',
  messagingSenderId: '838961745774',
  appId: '1:838961745774:web:f0ccbb74e416e725707b7f',
  measurementId: 'G-728GP2BF8R',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
